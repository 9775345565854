<template>
  <div class="komodo-date-picker">
    <v-menu :close-on-content-click="false">
      <template #activator="{ props: menuProps }">
        <komodo-input
          class="komodo-date-picker__input"
          v-bind="menuProps"
          :type="InputType.Text"
          :model-value="inputValue"
          :placeholder="placeholder"
          prepend-icon="today"
          :state="state"
          :clearable="clearable"
          @update:model-value="handleInput"
          @click:clear="handleClear"
        />
      </template>
      <v-date-picker
        class="komodo-date-picker__date-picker"
        :model-value="(datePickerValue as Date | Date[] | undefined)"
        :max="maxDate"
        :min="minDate"
        :hide-actions="true"
        :multiple="mode === CalendarMode.Range"
        @update:model-value="handleDatePickerInput"
      />
    </v-menu>
    <div
      v-for="(error, i) in errors"
      :key="i"
      class="komodo-date-picker__error"
    >
      {{ error }}
    </div>
  </div>
</template>

<script lang="ts">
import { computed } from 'vue'
import { endOfDay, startOfDay } from 'date-fns'
import isNil from 'lodash/isNil'

import KomodoInput from 'komodo2/components/KomodoInput.vue'

import { formatDate, validateInputValue } from 'shared/lib/util.ts'
import { DatepickerValue, InputType, InputState, CalendarMode, DateRange } from 'komodo2/types.ts'

export default {
  name: 'KomodoFormDatePicker'
}
</script>

<script setup lang="ts">
const PLACEHOLDERS = {
  [CalendarMode.Single]: 'YYYY-MM-DD',
  [CalendarMode.Range]: 'YYYY-MM-DD / YYYY-MM-DD'
}

const props = withDefaults(defineProps<{
  disabled?: boolean
  errors?: string[]
  maxDate?: Date
  minDate?: Date
  modelValue: DatepickerValue
  mode?: CalendarMode
  clearable?: boolean
}>(), {
  disabled: false,
  mode: CalendarMode.Single,
  maxDate: undefined,
  minDate: undefined,
  errors: undefined,
  clearable: true
})

const emit = defineEmits<{
  (e: 'update:model-value', value: DatepickerValue): void
}>()

const datePickerValue = computed(() => {
  if (props.mode === CalendarMode.Range) {
    const datesArray: Date[] = []

    if (props.modelValue) {
      if ((props.modelValue as DateRange).start !== undefined) {
        datesArray.push((props.modelValue as DateRange).start as Date)
      }

      if ((props.modelValue as DateRange).end !== undefined) {
        datesArray.push((props.modelValue as DateRange).end as Date)
      }

      return datesArray
    } else {
      return datesArray
    }
  } else {
    return props.modelValue ? props.modelValue : undefined
  }
})

const state = computed(() => {
  if (props.disabled) {
    return InputState.Disabled
  }
  return props.errors && props.errors?.length > 0 ? InputState.Error : InputState.Default
})

const isRange = computed(() => props.mode === CalendarMode.Range)

const placeholder = computed(() => PLACEHOLDERS[props.mode])

const inputValue = computed(() => {
  if (props.mode === CalendarMode.Range) {
    if (datePickerValue.value !== undefined && datePickerValue.value[0] && datePickerValue.value[1]) {
      return `${formatDate(new Date(datePickerValue.value[0] as Date), 'yyyy-MM-dd')} / ${formatDate(new Date(datePickerValue.value[1] as Date), 'yyyy-MM-dd')}`
    } else {
      return ''
    }
  } else {
    return datePickerValue.value === null || undefined ? '' : `${formatDate(datePickerValue.value as Date, 'yyyy-MM-dd')}`
  }
})

const handleDatePickerInput = (date) => {
  if (props.mode === CalendarMode.Range) {
    if (!date.length) {
      emit('update:model-value', {
        start: undefined,
        end: undefined
      })
      return
    }

    if (date.length > 2) {
      date = [date.slice(-1).pop(), null]
    }

    if (!isNil(date[1]) && date[1] < date[0]) {
      date = date.reverse()
    }

    emit('update:model-value', {
      end: date[1] instanceof Date ? endOfDay(date[1]) : undefined,
      start: startOfDay(date[0])
    })
  } else {
    emit('update:model-value', date)
  }
}

const handleInput = (value) => {
  const validatedValue = validateInputValue(value, isRange.value)
  if (validatedValue || validatedValue === '') {
    emit('update:model-value', validatedValue)
  }
}

const handleClear = () => {
  emit('update:model-value', undefined)
}
</script>

<style lang="scss" scoped>
.komodo-date-picker {
  position: relative;
  width: 100%;
  margin-right: 16px;

  &__input {
    background-color: white;
  }

  &__date-picker  {
    z-index: 100;

    :deep(.v-picker-title), :deep(.v-picker__header) {
      display: none;
    }
  }

  &__error {
    @include font-paragraph-p3-small-compact;

    color: $accent-red-1-base;
    margin-top: 4px;
  }
}
</style>
